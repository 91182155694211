import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Flex, Box, Heading } from "rebass"
import GatsbyImage from "gatsby-image"
import Link from "../components/link"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Paragraph, ExternalLink } from "../components"

const description =
  "Follow me and my walking journeys as I travel through France, Spain, Nepal and Australia with my two books Boots to Bliss and Boots to Freedom."

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      muxia: file(relativePath: { eq: "claude-tranchant-muxia.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout forceDesktopHeader>
      <SEO
        title="Claude Tranchant author of Boots to Bliss and Boots to Freedom"
        description={description}
        meta={[
          {
            name: "keywords",
            content:
              "Claude Tranchant, author, traveller, senior, camino, saint james way, nepal, larapinta",
          },
          {
            name: "author",
            content: "Claude Tranchant",
          },
        ]}
        openGraph={{
          namespace: "og: http://ogp.me/ns# profile: http://ogp.me/ns/profile#",
          meta: {
            description,
            type: "books.author",
            books: {
              book: [
                `${process.env.BASE_URL}/boots-to-bliss/`,
                `${process.env.BASE_URL}/boots-to-freedom/`,
              ],
            },
            profile: {
              first_name: "Claude",
              last_name: "Tranchant",
            },
          },
        }}
      />
      <Flex flexWrap={"wrap"}>
        <Box width={1}>
          <Heading fontSize={5} fontStyle={"italic"} mb={4} width={1}>
            My name is <Link to={"/"}>Claude Tranchant</Link>.
          </Heading>
        </Box>
        <Box width={[1, 1 / 2, 3 / 5]}>
          <Paragraph>
            In 2010, at the age of 64, I walked, alone, for 100 days on
            Saint-James’ Way, commonly known in Spain as the Camino, from
            Vezelay, Northern Central France, to Muxia in Galicia – Spain. On my
            return to Australia, I could not fit into our world anymore. After
            soul-searching and with the kind and wise words of a friend, I wrote{" "}
            <Link to={"/boots-to-bliss"} data-testid={"home-boots-to-bliss"}>
              Boots to Bliss
            </Link>
            .
          </Paragraph>
          <Paragraph>
            <Link to={"/boots-to-bliss"}>Boots to Bliss</Link> has sold over
            4000 copies and has been nominated as one of the six books to read
            to inspire anyone who wishes to walk the Camino alongside the books
            of{" "}
            <ExternalLink
              href={"https://paulocoelhoblog.com/"}
              title={"Paulo Coelho"}
              colour={"darkmagenta"}
            >
              Paulo Coelho
            </ExternalLink>
            ,{" "}
            <ExternalLink
              href={"https://shirleymaclaine.com/"}
              title={"Shirley MacLaine"}
              colour={"darkmagenta"}
            >
              Shirley MacLaine
            </ExternalLink>
            ,{" "}
            <ExternalLink
              href={"https://mariashriver.com/"}
              title={"Marcia Shaver"}
              colour={"darkmagenta"}
            >
              Marcia Shaver
            </ExternalLink>{" "}
            and others.
          </Paragraph>
        </Box>
        <Box width={[1, 1 / 2, 2 / 5]} pl={[0, 3]} mb={3}>
          <GatsbyImage fluid={data.muxia.childImageSharp.fluid} />
        </Box>
        <Box>
          <Paragraph>
            After my first long-distance pilgrimage, I yearned for more
            trekking. Between 2016 and 2018, in my seventies, I walked various
            treks, each time with a group - in Australia, in Nepal, and in Spain
            for the second time, The Camino Frances, with the group “Camino
            Skies” for a filmed documentary, as well as alone in Spain and
            Portugal.
          </Paragraph>
        </Box>
        <Box>
          <Paragraph>
            During these treks, I discovered that some of the ups and downs of
            my past life had not been completely resolved, though I thought I
            had in 2010.
          </Paragraph>

          <Paragraph>
            After the publication of{" "}
            <Link to={"/boots-to-bliss"}>Boots to Bliss</Link>, I received so
            many beautiful and inspiring comments from my readers and realised
            that I could help others in different ways, one of them was through
            my writing. Therefore I embarked on writing my second book:{" "}
            <Link
              to={"/boots-to-freedom"}
              data-testid={"home-boots-to-freedom"}
            >
              Boots to Freedom
            </Link>
            .
          </Paragraph>

          <Paragraph>
            I have learnt that in this world of fears we need to turn our
            greatest challenges into our greatest strengths. Negative thoughts
            take us along the wrong path.
          </Paragraph>

          <Paragraph>
            My life since my early childhood has not been an easy one, but I am
            grateful for my experiences so I can now help others.
          </Paragraph>

          <Paragraph>
            I wish you well and I hope I can take you on my journeys around the
            world through my writing.
          </Paragraph>
        </Box>
      </Flex>
    </Layout>
  )
}

export default IndexPage
